<template>
  <div>
    <v-container fluid>
      <v-card flat outlined tile :loading="loading">
        <v-row dense no-gutters>
          <v-col>
            <v-card-title>
              <v-chip class="mr-3" color="green" dark>
                {{ asset.imei || asset.serial_code }}
              </v-chip>

              {{ asset.brand }} {{ asset.model }}
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col>
                  <v-row>
                    <v-col>
                      <small class="d-block">Marca</small>
                      <h3>{{ asset.brand }}</h3>
                    </v-col>
                    <v-col>
                      <small class="d-block">Modello</small>
                      <h3>{{ asset.model }}</h3>
                    </v-col>
                    <v-col>
                      <small class="d-block">Seriale</small>
                      <h3>{{ asset.serial_code | placeholder('-') }}</h3>
                    </v-col>
                    <v-col>
                      <small class="d-block">IMEI/MSISDN</small>
                      <h3>{{ asset.imei | placeholder('-') }}</h3>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2">
                      <small class="d-block">Stato</small>
                      <h3>{{ asset.status }}</h3>
                    </v-col>

                    <v-col :cols="1">
                      <small class="d-block">Kasko attiva</small>
                      <h3>{{ asset.has_kasko ? 'Sì' : 'No' }}</h3>
                    </v-col>
                    <v-col>
                      <small class="d-block">Data di attivazione</small>
                      <h3>{{
                          asset.activated_at | momentOr('DD/MM/YYYY', '-')
                        }}</h3>
                    </v-col>
                    <v-col>
                      <small class="d-block">Data assegnazione</small>
                      <h3>{{ asset.assigned_at | momentOr('DD/MM/YYYY', '-') }}</h3>
                    </v-col>
                    <v-col>
                      <small class="d-block">Data inserimento</small>
                      <h3>{{ asset.created_at | momentOr('DD/MM/YYYY', '-') }}</h3>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <small class="d-block">Cliente assegnatario</small>

                      <h3 v-if="asset.customer">
                        <router-link
                          :to="{
                        name: 'customers_detail',
                        params: { id: asset.customer.id },
                      }"
                        >
                          {{ asset.customer.common_name }}
                        </router-link>
                        <br/>
                        <small class="text--secondary">{{
                            asset.customer.address
                          }}</small>
                      </h3>
                      <h3 v-else> Non assegnato </h3>
                    </v-col>
                    <v-col>
                      <small class="d-block">Sottoscrizione</small>
                      <h3 v-if="asset.plans_subscription">
                        {{ asset.plans_subscription.plan.code }}
                        {{ asset.plans_subscription.code }}<br/>
                        <small class="text--secondary">
                          Del
                          {{
                            asset.plans_subscription.date_start
                              | momentOr('DD/MM/YYYY', '-')
                          }}
                        </small>
                      </h3>
                      <h3 v-else> Non assegnato </h3>
                    </v-col>

                    <v-col>
                      <small class="d-block">Dipendente Assegnatario</small>
                      <h3 v-if="asset.customers_user">
                        {{ asset.customers_user.last_name }}
                        {{ asset.customers_user.first_name }}
                      </h3>
                      <h3 v-else> Non assegnato </h3>
                    </v-col>
                    <v-col>
                      <small class="d-block">Sede Cliente</small>
                      <h3 v-if="asset.customers_location">
                        {{
                          [
                            asset.customers_location.address,
                            asset.customers_location.city,
                          ]
                            .filter(Boolean)
                            .join(' - ')
                        }}
                        <br/>
                        <small class="text--secondary">{{
                            asset.customers_location.name
                          }}</small>
                      </h3>
                      <h3 v-else> Non assegnato </h3>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="3">
                  <v-row>
                    <v-col>
                      <small class="d-block">Note</small>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="white-space-pre-line scrollable">
                      {{ asset.notes }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
        </v-row>
        <v-row dense no-gutters>
          <v-col>
            <v-divider></v-divider>
            <v-card-actions v-if="canUser('customers.assets', 'update')">
              <v-btn
                v-if="asset.id && canUser('customers.assets', 'update')"
                depressed
                text
                small
                color="primary"
                @click="dialog = true"
              >
                <v-icon color="primary" left>edit</v-icon>
                Modifica dettagli
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-dialog
      v-model="dialog"
      max-width="80%"
      :fullscreen="$vuetify.breakpoint.mobile"
      :retain-focus="false"
      @click:outside="refresh"
    >
      <v-card>
        <v-card-title><!-- This is here for spacing --></v-card-title>
        <v-card-text>
          <CustomerAssetsForm
            v-if="asset.id"
            :edit="true"
            @submit="dialog = false"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-container v-if="asset.id" fluid>
      <v-tabs v-model="tab" background-color="transparent">
        <!-- Storico aggiornamenti -->
        <v-tab href="#history">Storico</v-tab>
        <v-tab-item class="" value="history">
          <AssetHistoryTable/>
        </v-tab-item>
        <!-- /piani sottoscritti-->

        <!-- Tickets -->
        <v-tab v-if="canUserSee('tickets') && asset.tickets" href="#tickets">
          Tickets
        </v-tab>
        <v-tab-item
          v-if="canUserSee('tickets') && asset.tickets"
          class="pa-1"
          value="tickets"
        >
          <AssetsTicketTable :items="asset.tickets"/>
        </v-tab-item>
      </v-tabs>
    </v-container>
  </div>
</template>

<script>
import {authComputed} from '@state/helpers.js'
import {mapState, mapActions, mapMutations} from 'vuex'
import AssetHistoryTable from '@components/customer_assets/AssetsHistoryTable.vue'
import AssetsTicketTable from '@components/customer_assets/AssetsTicketTable.vue'
import CustomerAssetsForm from '@components/customer_assets/CustomerAssetsForm.vue'

export default {
  name: 'Detail',
  page: {
    title: 'Dettagli asset',
  },
  components: {
    AssetHistoryTable,
    AssetsTicketTable,
    CustomerAssetsForm,
  },
  data() {
    return {
      tab: null,
      dialog: false,
    }
  },
  computed: {
    ...authComputed,
    ...mapState('customersAssets', {
      asset: 'current',
      loading: 'loading',
    }),
  },
  mounted() {
    this.getAsset()
  },
  beforeDestroy() {
    this.resetCurrent()
  },
  methods: {
    ...mapActions('customersAssets', {
      setCurrent: 'setCurrent',
    }),
    ...mapMutations('customersAssets', {
      resetCurrent: 'RESET_CURRENT',
    }),
    replaceNewLine(str) {
      return str.replace(/(?:\r\n|\r|\n)/g, '<br>')
    },
    async getAsset() {
      await this.setCurrent(this.$route.params.id)
    },
    async refresh() {
      this.resetCurrent()
      await this.getAsset()
    },
  },
}
</script>

<style>
.v-tabs-items {
  background-color: transparent !important;
}

.white-space-pre-line {
  white-space: pre-line;
}

.scrollable {
  height: 230px;
  overflow-y: scroll;
}
</style>
