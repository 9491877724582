<template>
  <BaseTable
    ref="table"
    module="assetsHistory"
    :hide-title="true"
    :headers="headers"
    :elevation="0"
    sortby="logged_at"
  >
  </BaseTable>
</template>

<script>
import { authComputed } from '@state/helpers.js'
import { mapMutations, mapState } from 'vuex'
import BaseTable from '@components/structure/base-table.vue'
import { createHelpers } from 'vuex-map-fields'

const { mapFields } = createHelpers({
  getterType: 'assetsHistory/getFiltersFields',
  mutationType: 'assetsHistory/SET_FILTER_FIELDS',
})

export default {
  name: 'AssetsHistoryTable',
  components: { BaseTable },
  data: () => ({
    headers: [
      {
        text: 'Descrizione',
        value: 'description',
      },
      {
        text: 'Valore',
        value: 'value',
      },
      {
        text: 'Utente',
        value: 'user',
      },
      {
        text: 'Data',
        value: 'logged_at',
      },
    ],
  }),
  computed: {
    ...authComputed,
    ...mapState('customersAssets', {
      asset: 'current',
    }),
    ...mapFields(['asset_id']),
  },
  mounted() {
    this.asset_id = this.asset.id
  },
  beforeDestroy() {
    this.resetFilters()
  },
  methods: {
    ...mapMutations('assetsHistory', {
      resetFilters: 'RESET_FILTER_FIELDS',
    }),
    refresh() {
      this.$refs.table.refresh()
    },
  },
}
</script>

<style scoped></style>
