<template>
  <BaseTable
    :hide-title="true"
    :headers="headers"
    :items="items"
    :total-rows="items.length"
    :actions="actions"
    :elevation="0"
  />
</template>

<script>
import BaseTable from '@components/structure/base-table.vue'
import { authComputed } from '@state/helpers.js'

export default {
  name: 'AssetsTicketsTable',
  components: {
    BaseTable,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'ID',
          align: 'start',
          value: 'external_id',
        },
        {
          text: 'Stato',
          value: 'status',
        },
        {
          text: 'Esito',
          value: 'resolution',
        },
        {
          text: 'Presa In Carico',
          value: 'managed_at',
        },
        {
          text: 'Data Appuntamento',
          value: 'date_appointment',
        },
        {
          text: 'Inserito',
          value: 'created_at',
        },
        {
          text: 'Chiuso',
          value: 'closed_at',
        },
      ],
      actions: [
        {
          label: 'Val al Ticket',
          icon: 'mdi-magnify',
          color: 'primary',
          button: true,
          to: ({ id }) => ({ name: 'tickets_detail', params: { id } }),
        },
      ],
    }
  },
  computed: {
    ...authComputed,
  },
}
</script>

<style scoped></style>
